* {
    font-family: "Helvetica", san-serif;
    font-weight: lighter;
}

.about-container {
    font-family: Helvetica;
}

.head-info {
  display: flex;
}

.about-pic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
  border-radius: 40px;
}

.pic-info {
  color: rgb(120, 120, 120);
  margin-left: 24%;
}

.head-info > p {
    color: rgb(180, 0, 0);
    text-align: right;
    width: 50%;
    position: absolute;
    top: 450px;
    right: 110px;
}

.about-info {
    margin: auto;
    width: 75%;
}
.about-info > .graphics {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    grid-gap: 7%;
    grid-template-columns: 28% 28% 28%;
}

.about-info > p {
    font-size: 17px;
}

.about-info > h1 {
  color: rgb(124, 0, 0);
  text-shadow: 1px 0px 1px black;
}

.section2 {
    display: block;
}

.mission-statement {
    display: flex;
    border-radius: 25px;
}

.ms-text {
    margin-top: auto;
    margin-bottom: auto;
}

.ms-text > h1 {
  font-size: 35px;
  color: rgb(124, 0, 0);
  text-shadow: 1px 0px 1px black;
}

.ms-text > p {
  font-size: 17px;
  width: 83%;
}

.ms-text > hr {
    border-width: 1px;
    width: 270px;
}

.ms-pic {
  width: 500px;
  border-radius: 15px;
}

.motto {
    display: flex;
    border-radius: 15px;
    text-align: right;
}

.motto-text {
  margin-top: auto;
  margin-bottom: auto;
  width: 60%;
}

.motto-text > h1 {
  font-size: 30px;
  color: rgb(124, 0, 0);
  text-shadow: 1px 0px 1px black;
}

.motto-text > p {
  font-size: 17px;
}

.motto-text > hr {
  border-width: 1px;
  width: 170px;
  margin-left: auto;
}

.motto-pic {
  width: 500px;
  border-radius: 15px;
}

.card {
    width: auto;
    height: 353px;
    background: #FFFAFA;
    mix-blend-mode: normal;
    border-radius: 35px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    box-shadow: 4px 4px 5px gold, 0 0 10px rgb(181, 0, 0);
}


/* THIS FIXES THE FLICKER ON HOVER FOR THE PIE CHARTS */
svg > g > g:last-child { 
    pointer-events: none 
}

/* .about-floating1 {
    color: #9e9e9e;
    font-size: 16px;
    position: fixed;
    transform: rotate(-90deg);
    text-align: right;
    bottom: 15%; 
    left: -695px;
}

.about-floating2 {
    color: #9e9e9e;
    font-size: 16px;
    position: fixed;
    transform: rotate(90deg);
    text-align: right;
    top: 45%; 
    right: -695px;
} */

@media screen and (max-width: 1350px) {
    .graphics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .card {
        width: 280px;
    }
}

@media screen and (max-width: 1100px) {
    .about-info > .graphics {
        display: block;
    }
    .card {
        width: 360px;
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
    }
    .about-info > h1 {
        text-align: center;
    }
    .about-pic {
        width: 80%;
    }
    .pic-info {
        margin-left: 15%;
    }

    .mission-statement {
        display: block;
        text-align: center;
    }

    .motto {
        display: block;
        text-align: center;
    }

    .ms-text {
        margin-left: auto;
        margin-right: auto;
    }

    .ms-text > p {
        width: auto;
    }

    .motto-text {
        margin-left: auto;
        margin-right: auto;
    }

    .ms-pic {
        display: block;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .motto-pic {
        display: block;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

  .motto-pic {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 768px) {
    .about-floating1 {
        font-size: 15px;
        left: -650px;
    }
    
    .about-floating2 {
        size: 10px;
    }

    .about-info > p {
        text-align: center;
    }

    .card {
        width: 300px;
    }

}
