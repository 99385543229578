* {
  font-family: "Helvetica", san-serif;
  font-weight: lighter;
}

.page-container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3% 0% 1% 0%;
  width: 100%;
}

.title {
  font-size: 250%;
  line-height: 54px;
  padding: 1% 2% 1% 2%;
  padding: 0% 2%;
}

.home-slider-container {
  position: relative;
  width: 80%;
  padding-top: 45%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.total-pillar-container {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.pillar {
  justify-content: center;
  width: 33%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 50%;
  position: relative;
  overflow: hidden;
}

.icon img {
  position: absolute;
  width: 90%;
  height: auto;
  top: 0;
  left: 0;
}

.subtitle {
  color: rgb(0, 0, 0);
  font-weight: 20;
  font-size: 24px;
  padding: 1em;
}

.caption {
  color: rgb(0, 0, 0);
  font-size: 20px;
  padding: 1em;
  height: 30%;
  padding-bottom: 10%;
  text-align: justify;
  inline-size: 150px;
  hyphens: auto;
  width: 75%;
}

.border-with-diamonds {
  position: relative;
  width: 90%;
  min-height: 2px;
  background-color: rgb(0, 0, 0);
  margin: auto;
  text-align: center;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -1.5px;
    width: 5px;
    height: 5px;
    background-color: black;
    transform: rotate(45deg);
  }

  &::before {
    left: -5px;
  }

  &::after {
    right: -5px;
  }
}

.right-line-with-triangle {
  position: relative;
  width: 40%;
  height: 2px;
  background-color: black;
  margin: 0px 11% 0px 0%;
}

.left-line-with-triangle {
  position: relative;
  width: 40%;
  height: 2px;
  background-color: black;
  margin: 0px 0% 0px 11%;
}

.right-line-with-triangle::before {
  content: "";
  position: absolute;
  left: 99%;
  top: -2px;
  width: 0;
  height: 0px;
  border-top: 3px solid transparent;
  border-right: 10px solid black;
  border-bottom: 3px solid transparent;
}

.left-line-with-triangle::before {
  content: "";
  position: absolute;
  right: 99%;
  top: -2px;
  width: 0;
  height: 0px;
  border-top: 3px solid transparent;
  border-left: 10px solid black;
  border-bottom: 3px solid transparent;
}

@media (max-width: 768px) {
  .page-container {
    flex-direction: column;
  }

  .title-container {
    padding: 0.05%;
  }

  .home-slider-container {
    width: 100%;
    padding-bottom: 20%;
  }

  .total-pillar-container {
    flex-direction: column;
    width: 80%;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
  }

  .pillar {
    width: 90%;
    height: 33%;
    padding: 10% 0%;
  }

  .icon {
    width: 55%;
    height: auto;
  }

  .right-line,
  .left-line {
    width: 50%;
  }

  .border-with-diamonds {
    width: 75%;
  }

  .caption {
    width: 100%;
    font-size: 18px;
  }

  .title-container {
    margin-top: 20%;
  }

  .title {
    width: 50%;
  }

  .right-line-with-triangle {
    position: relative;
    width: 40%;
    height: 2px;
    margin: 0px 11% 0px 0%;
  }

  .left-line-with-triangle {
    position: relative;
    width: 40%;
    height: 2px;
    margin: 0px 0% 0px 11%;
  }

  .right-line-with-triangle::before {
    left: 95%;
  }

  .left-line-with-triangle::before {
    right: 95%;
  }
}
