.master {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  /* border: 5px solid red; */
}

.rush-container {
  width: 55%;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  align-items: center;
}

.rush-text {
  margin: 0px 5%;
  text-align: center;
  font-family: "Matcha";
  /* border: 3px solid red; */
  align-self: center;
  width: 60%;
  margin-bottom: 10%;
}

.rush-text > h1 {
  margin: auto;
}

.rush-text > p {
  text-indent: 5%;
  font-style: normal;
  text-align: left;
  font-size: medium;
}

.rush-pic {
  width: 550px;
  border-radius: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
}

.rush-buttons {
  text-align: center;
  padding: 20px;
}

.rush-bttn {
  /* border: none; */
  font-size: 20px;
  border-color: black;
  /* border: none; */
  padding: 15px;
  width: 200px;
  margin: 10px 10px; /*first value adjusts the margin above and below. Secon value adjusts the margin to the right and left of the buttons.*/
  background-color: #4d4d4d;
  color: white;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 15px;
  box-shadow: 8px 8px 5px 0 rgba(0, 0, 0, 0.5);
}

.rush-bttn span {
  display: inline-block;
  position: relative;
  /* background-color: #ffffff; */
  transition: 0.5s;
}

.rush-bttn span:after {
  font-size: 35px;
  content: "\00bb";
  position: absolute;
  opacity: 0;
  margin-top: -11px;
  right: -25px;
  transition: 0.5s;
}

.rush-bttn:hover span {
  padding-right: 30px;
}

.rush-bttn:hover span:after {
  opacity: 1;
  right: 0;
}

/* .rush-floating1 {
    position: fixed;
    transform: rotate(-90deg);
    text-align: right;
    bottom: 15%; 
    left: -40px;
}

.rush-floating2 {
    position: fixed;
    transform: rotate(-90deg);
    text-align: right;
    top: 45%; 
    right: -40px;
} */

@media screen and (max-width: 960px) {
  .rush-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .rush-pic {
    text-align: center;
    width: 100%;
    border-radius: 40px;
  }

  .rush-text {
    width: 100%;
  }

  .rush-text > p {
    text-align: center;
  }

  /* .rush-floating1 {
        left: -50px;
    }
    .rush-floating2 {
        right: -50px;
    } */
}
/* @media screen and (max-width: 768px) {
    .rush-container {
        display: block;
    }
    .rush-pic {
        width: 100%;
        border-radius: 40px;
    }
} */
