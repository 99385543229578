* {
    padding: 0;
    margin: 0;
}

#container {
    margin-bottom: 2em;
}

.className {
    padding: 30px 0;
    text-decoration: underline;
    text-align: center;
    font-size: 2em;
    font-weight: 300;
}

.classMembers {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    row-gap: 3em;
}

@media screen and (max-width: 1200px) {
    .classMembers {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 850px) {
    .classMembers {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1500px) {
    .classMembers {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1900px) {
    .classMembers {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}