* {
    padding: 0;
    margin: 0;
}

.header {
    width: 100vw;
    
    font-family: "Helvetica Neue", san-serif;
    height: 8rem;
    background: #8B0000;
    color: white;
    display: flex;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.5);
}

a {
    color: white;
    text-decoration: none;
}

#main {
    display: flex;
}

headerTitle {
    margin: auto auto auto 3em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crestImage {
    height: 4.5em;
    margin-right: 10px;
}

headerTitle h1 {
    font-size: 2.1em;
    font-weight: 300;
    padding-bottom: 3px;
    border-bottom: solid white;
    border-width: 1px; 
}

headerTitle h2 {
    font-size: 15px;
    font-weight: 400;
    padding: 5px 0;
}

headerSubtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    gap: 1em;
    margin: auto 3em auto auto;
}

headerSubtitle a {
    padding: 4px 7px;
    border-radius: 5px;
}

.icons, #check {
  display: none;
}

headerSubtitle a.active, headerSubtitle a:hover, .icons:hover {
    background: #a82020;
    transition: 0.3s;
}

@media screen and (max-width: 675px) {

    .header {
        height: 6rem;
    }

    headerTitle {
        margin: auto auto auto 1.5em;
    }
    
    .crestImage {
        height: 3.75em;
        margin-right: 10px;
    }
    
    headerTitle h1 {
        font-size: 1.75em;
    }
    
    headerTitle h2 {
        font-size: 0.8em;
    }

    .icons {
        display: flex;
        margin: auto 5% auto auto;
        color: white;
        cursor: pointer;
        font-size: 30px;
        padding: 5px;
        border-radius: 5px;
    }

  #close {
    display: none;
  }

  #check:checked ~ .icons #open {
    display: none;
  }

  #check:checked ~ .icons #close {
    display: flex;
  }

    headerSubtitle {
        position: absolute;
        display: block;
        top: 100%;
        width: 100%;
        height: 0;
        overflow: hidden;
        font-size: 18px;
        background: #8B0000;
        transition: height 0.5s ease;
    }

    #check:checked~headerSubtitle {
        height: 13em;
    }
    
    headerSubtitle a {
        display: block;
        width: fit-content;
        padding: 7px;
        margin: 15px auto;
    }

}
