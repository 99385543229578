* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
}

.brotherProfile {
    padding: 0 10px;
    width: 12em;
}

.brotherCard {
    height: 16em;
    perspective: 1500px;
    transition: all 0.25s ease;
    border-radius: 5%;
}

.brotherCard:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.flip:hover{
    box-shadow: 0 0 1px black;
}

.flip {
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
    transform-style: preserve-3d;
    border-radius: 5%;
}

.flipCard {
    transform: rotateY(181deg); /* for some reason it flips the other way if the value is exactly 180 so leave it at 181*/
}

.front, .back {
    height: 100%;
    width: 100%;
    border-radius: 5%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    backface-visibility: hidden;
    pointer-events: none;
    background-color: #FAF9F6;
}

.back{
    transform: rotateY(180deg);
}

.headshot {
    height: 100%;
    width: 100%;
    pointer-events: none;
    object-fit: cover;
    border-radius: 5%;
}

.details {
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.details h2 {
    font-size: 120%;
    font-weight: 200;
    margin: 5% 0 0 5%;
    pointer-events: none;
    color: #8b0000;
    padding: 0;

}

.details h3 {
    font-size: 100%;
    font-weight: 200;
    margin: 0 5%;
    pointer-events: none;
    border: none;
}

.info {
    width: 100%;
    height: fit-content;
}

.info h1 {
    width: 100%;
    margin-top: 1em;
    padding: 0;
    font-size: 120%;
    font-weight: 400;
}

@media screen and (max-width: 550px) {
    
    .brotherProfile {
        width: 9em;
    }
    
    .brotherCard {
        height: 12em;
    }

    .details h2 {
        font-size: 100%;
    }
    
    .details h3 {
        font-size: 80%;
    }
}