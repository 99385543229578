* {
  font-family: "Helvetica", san-serif;
  font-weight: lighter;
}

.imageSlider {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  /* position: relative; */
}

.slide {
  border-radius: 0.5rem;
  border-radius: 15px;
  max-width: 100%;
  /* height: 100%; */
  filter: blur(1.5px);
  display: block;
  object-position: center;
  object-fit: cover;
  opacity: 1;
  transition: opacity 3s cubic-bezier(0.19, 1, 0.56, 1);
}

.slide-hidden {
  opacity: 0;
  max-height: 0;
  max-width: 0;
  pointer-events: none;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: grey;
}

.center-linked-text-container {
  background-color: rgba(154, 154, 154, 0.25);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) inset;
  border-radius: 15px;
  display: inline-block;
  padding: 1%;
  &:hover {
    background-color: rgba(150, 150, 150, 0.5);
  }
}

.hidden {
  left: 100%;
  display: none;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

.center-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 100%;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  align-self: center;
  margin-top: auto;
  transition: opacity 5s cubic-bezier(0.19, 1, 0.56, 1);
}

.center-text {
  margin: 5px 15px;
  font-size: 2rem;
  opacity: 100%;
  text-shadow: 1px 1px 2px black;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-weight: 300;
  text-align: center;
}

.arrow {
  border-radius: 5px;
  padding: 5px 20px;
  margin: 0px 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;

  & span,
  &::before,
  &::after {
    background-color: #000000;
    content: "";
    display: block;
    width: 30px;
    height: 2px;
    position: absolute;
  }

  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }

  & span {
    width: 0;
  }
  &:hover span {
    width: 42.42px;
  }

  &.arrow--left {
    left: 0;
    & span,
    &:before,
    &:after {
      left: 0;
      transform-origin: left 50%;
    }

    &:before,
    &:after {
      transition: left 0.3s 0.05s;
    }

    & span {
      transition: width 0.3s, left 0.3s 0.05s;
    }

    &:hover {
      & span,
      &:before,
      &:after {
        left: -10px;
      }
    }
  }

  &.arrow--right {
    right: 0%;
    & span,
    &:before,
    &:after {
      right: 0;
      transform-origin: right 50%;
    }
    &:before,
    &:after {
      transition: right 0.3s 0.05s;
    }

    & span {
      transition: width 0.3s, right 0.3s 0.05s;
    }

    &:hover {
      & span,
      &:before,
      &:after {
        right: -10px;
      }
    }
  }
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .slide {
    width: 18rem;
  }

  .indicators {
    bottom: 1.5rem;
  }

  .center-text {
    font-size: 1.3rem;
  }

  .arrow {
    padding: 50px 20px;
    width: 40px;
    height: 50px;

    & span,
    &::before,
    &::after {
      width: 15px;
      height: 2px;
    }

    &.arrow--left {
      left: 2%;
      &:hover {
        & span,
        &:before,
        &:after {
          left: -5px;
        }
      }
    }

    &.arrow--right {
      right: 2%;
      &:hover {
        & span,
        &:before,
        &:after {
          right: -5px;
        }
      }
    }
  }
}
